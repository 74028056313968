.b_cart {}

.b_cart__table_wrapper {
    background-color: #f6f6f6;
    padding: 25px 45px;
    margin-bottom: 12px;

    @include break(xs) {
        padding: 15px;
    }
}

.b_cart__thead {
    border-bottom: 1px solid #a5bbc8;
}

.b_cart__table_cell_head {
    font-family: 'open_sansregular';

    font-size: 18px;
    line-height: 22px;
    padding-bottom: 15px;
    color: #666;
    padding-right: 15px;

    @include break(xs) {
        display: block;
        width: 100%;
        padding: 0;
        text-align: center !important;
    }

    &:last-child {
        padding-right: 0;

        @include break(xs) {
            padding-bottom: 15px;
        }
    }

    &--text_center {
        text-align: center;
    }

    &--text_right {
        text-align: right;
    }
}

.b_cart__pic_name_wrapper {
    display: flex;

    @include break(xs) {
        display: block;
    }
}

.b_cart__spacer {
    padding: 5px;
}

.b_cart__item_row {
}

.b_cart__item {
    font-family: 'open_sansregular';

    color: #333;
    font-size: 16px;
    line-height: 1.7em;
    vertical-align: top;
    padding-right: 15px;

    @include break(xs) {
        display: block;
        width: 100%;
        padding: 0;
        text-align: center !important;
    }

    &--text_center {
        text-align: center;
    }

    &--text_right {
        text-align: right;
    }

    &--spacer {
        padding-bottom: 20px;

        @include break(xs) {
            padding: 0;
        }
    }

    &--bold {
        font-family: 'open_sansbold';
    }

    &--dark_blue {
        color: #666;
    }

    &:last-child {
        padding-right: 0;
    }

    // Give bottom spacing and borders to item rows on mobile.
    .b_cart__item_row & {
        &:last-child {
            @include break(xs) {
                margin-bottom: 15px;
                padding-bottom: 15px;
                border-bottom: 1px solid #ccc;
            }
        }
    }
}

.b_cart__item_pic_wrapper {
    margin-right: 10px;
    min-width: 62px;

    &:empty {
        display: none;
    }

    @include break(xs) {
        margin: 0 0 15px 0;
    }
}

.b_cart__pic {
    border: 3px solid #ccc;
    max-height: 90px;
}

.b_cart__table {
    @include break(xs) {
        border: none;
    }
}

.b_cart__item_name {
    word-break: break-all;
}

.b_cart__sku {
    font-family: 'open_sansregular';
    font-size: 13px;
    line-height: 16px;
    color: #333;
    word-break: break-all;
}

.b_cart__item_qty {
    width: 60px;
    text-align: center;
    color: blue;

}

.b_cart__item_remove_link {
    font-family: 'open_sansregular';

    color: #17aeb5;
    font-size: 13px;
    text-decoration: underline;
}

.b_cart__bottom_line {
    border-bottom: 1px solid #a5bbc8;

    @include break(xs) {
        display: none;
    }
}

.b_cart__button_wrapper {
    text-align: right;

    @include break(md) {
        text-align: center;
    }
}

.b_cart__button {
    display: inline-block;
    margin-left: 15px;

    @include break(md) {
        margin: 0 15px 25px;
    }

    @include break(xs) {
        display: block;
    }
}

.b_cart__item_prod_pic {
    max-height: 180px;
    max-width: 70px;
}
#store-cart-container {
    border: none;
    margin: 0;
    padding: 0;
}
.b_cart__item--spacer {
    display: none;
}
#store-cart-table .b_cart__mobile_flex {
    .b_cart__item {
        border-top: none !important;
        vertical-align: middle !important;
    }
    div {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .b_cart__coupon_code_label {
            margin: 0;
        }
        .form-control {
            width: 30%;
            margin: 0 20px;
        }
        .b_button {
            width: 160px;
            margin: 0;
        }
    }
}
#payment_option_layer_start {
    .col-md-6 {
        float: none;
    }
    .col-md-4:last-child {
        float: none;
        .input-group {
            display: flex;
            input {
                width: 120px !important;
            }
        }
    }
}
#payment_cc_exp_month {
    width: 140px !important;
}
#payment_cc_exp_year {
    width: 100px !important;
}
.store-catalog-product-inventory-msg {
    margin-left: 0;
    margin-top: 0;
}
