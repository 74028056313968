.map {
    border-bottom: 1px solid var(--primary);
}
.map__title {
    text-align:     center;
    @extend %sans_light;
    font-size:      30px;
    color:          var(--black);
    padding:        30px 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: none;
}
.map__gmap {
    height: 600px;
    @include break(xs) {
        height: 300px;
    }
}
.map__view_all {
    display: none;
}
.map__location {
    @include break(md) {
        width: 100%;
        margin-bottom: 30px;
    }
    flex-grow: 1;
    @extend %sans_bold;
    font-size: 22px;
    color:     var(--black);
}
.map__contacts {
    flex-grow: 1;
    p {
        @extend %sans_regular;
        font-size: 16px;
        color: var(--black);
        line-height: 1.4;
        .phone--map,
        .phone--map_fax {
            color: var(--black);
        }
        .phone--map {
            &:hover, &:active, &:focus {
                color: var(--primary);
            }
        }
    }
}
.map__contacts_title {
    @extend %sans_bold;
    color:     var(--black);
    margin-top: 0;
    font-size: 16px;
}
.map__hours {
    flex-grow: 1;
    p {
        @extend %sans_regular;
        font-size: 16px;
        color: var(--black);
        line-height: 1.4;
    }
}
.map__marker {
    background-color: var(--secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    height: 60px;
    width: 60px;
    margin: 0 10px;
    text-shadow: 0 0 0 black;
    transition: all .4s;
    &:before {
        content: '\f3c5';
        font-family: "Font Awesome 5 Free", sans-serif;
        font-weight: 900;
        font-size: 30px;
        color: var(--white);
    }
    span {
        display: none;
    }
    &:hover, &:active, &:focus {
        text-shadow: 2px 2px 0 black;
    }
}
.map__get_directions {
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    height: 60px;
    width: 60px;
    margin: 0 10px;
    text-shadow: 0 0 0 black;
    transition: all .4s;
    &:before {
        content: '\f124';
        font-family: "Font Awesome 5 Free", sans-serif;
        font-weight: 900;
        font-size: 30px;
        color: var(--white);
    }
    span {
        display: none;
    }
    &:hover, &:active, &:focus {
        text-shadow: 2px 2px 0 black;
    }
}
.map__hours_title {
    @extend %sans_bold;
    color:     var(--black);
    margin-top: 0;
    font-size: 16px;
}
.map__navigation {
    flex-grow: 1;
    display: flex;
}
