.contact_form {
    width            : 50%;
    background-color : #f0e7e2;
    display          : flex;
    justify-content  : center;
    align-items      : center;
    @include break(md) {
        width : 100%;
        order: 2;
    }
}
.contact_form__wrapper {
    width   : 100%;
    padding : 7% 20%;
    @include break(xs) {
        padding: 7%;
    }
}
.contact_form__title {
    text-align     : center;
    color          : var(--black);
    @extend %sans_light;
    font-size      : 34px;
    text-transform : uppercase;
    letter-spacing : 2px;
    display: none;
}
.contact_form__group {
}
.contact_form__input {
    width      : 100%;
    padding    : 0 50px;
    margin-top : 20px;
    @include break(xs) {
        padding: 0;
    }
}
.contact__input_el {
    @include break(xs) {
        border-radius: 10px;
        padding: 12px 20px;
    }
    width            : 100%;
    padding          : 10px 20px;
    border-radius    : 15px;
    border           : 1px solid var(--black);
    background-color : #f0e7e2;
    color            : var(--black);
    &:focus {
        outline : 0;
    }
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color      : var(--black);
        font-style : italic;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color      : var(--black);
        font-style : italic;
    }
    &:-ms-input-placeholder {
        /* IE 10+ */
        color      : var(--black);
        font-style : italic;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color      : var(--black);
        font-style : italic;
    }
}
.contact_form__recaptch {
    margin-top: 20px;
    display         : flex;
    justify-content : center;
}

/* Error Message */
.form_error_main {
    li {
        position: relative;
        padding-left: 25px;
        &:before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #A94442;
            position: absolute;
            left: 0;
            top: 8px;
        }
    }
}
