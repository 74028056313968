/* Top Section */
.top_section {
}

.top_section__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  @include break(xs) {
    flex-direction: column;
  }
}

.top_section__item {
  flex: 1;
  position: relative;
  background-size: contain !important;
  &:nth-child(1) {
    background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("/assets/images/supplements.png");
  }
  &:nth-child(2) {
    background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("/assets/images/labs.png");
  }
  &:nth-child(3) {
    background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("/assets/images/consults.png");
  }
  a {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
  img {
    visibility: hidden;
    max-width: 100%;
    display: block;
    margin: auto;
  }
  h2 {
    width: 100%;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0;
    color: var(--white);
    display: block;
    position: absolute;
    bottom: 20px;
  }
}

/* Featured Products */
.featured_products {
  background: #ffffff;
}

.featured_products__wrapper {
  display: flex;
  align-items: center;
  @include break(xs) {
    flex-direction: column;
  }
}

.featured_products__info {
  background-color: rgba(54, 95, 4, .8);
  padding: 20px;
  position: relative;
  width: 100%;
  bottom: 40px;
}

.featured_products__img {
  min-height: 242px;
}

.featured_products__item {
  margin-bottom: -40px;
  &:hover, &:active, &:focus {
    .featured_products__info {
      background-color: rgba(255, 100, 63, .8);
    }
    span {
      color: var(--primary);
    }
  }
  img {
    max-height: 200px;
    max-width: 100%;
    display: block;
    margin: auto;
  }
  h3 {
    margin: 0;
    font-size: 30px;
    text-align: left;
    margin-bottom: 20px;
    letter-spacing: 2px;
    color: var(--white);
    display: block;
    a {
      color: var(--white);
      &:hover, &:active, &:focus {
        color: var(--white);
      }
    }
  }
  p {
    margin: 0;
    font-size: 16px;
    text-align: left;
    margin-bottom: 20px;
    letter-spacing: 2px;
    color: var(--white);
    display: block;
  }
  span {
    color: var(--secondary);
    font-weight: 700;
  }
}

/* Supplements By Function */
.supplements_function {
  background: #f0e7e2;
}

.supplements_function__list {
  column-count: 4;
  line-height: 38px;
  list-style-type: none;
  text-transform: uppercase;
  font-family: var(--sans_light), Arial, sans-serif;
  @include break(md) {
    column-count: 3;
  }
  @include break(sm) {
    column-count: 2;
  }
  @include break(xs) {
    column-count: 1;
  }
  li {
  }
  a {
    padding: 0 20px;
    color: var(--black);
    &:hover, &:active, &:focus {
      color: var(--white);
      background-color: var(--primary);
      border-radius: 25px;
      padding: 5px 20px;
    }
  }
}

/* Supplements By Brand */
.supplements_brand {
  background: #ffffff;
}

.supplements_brand__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  @include break(xs) {
    justify-content: space-around;
  }
}

.supplements_brand__item {
  width: 22%;
  padding: 20px;
  margin: 20px 1.5%;
  display: flex;
  position: relative;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  @include break(xs) {
    width: 250px;
  }
  a {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  img {
    max-width: 100%;
    display: block;
    margin: auto;
  }
}

/* Generic Styles */
body[class*="catalog.html"] {
  .wrapper__subpage {
    display: none;
  }
}

body:not([class*="catalog.html"]) {
  .store_wrapper {
    display: none;
  }
  .homepage-banner {
    display: none;
  }
}

body[class*="cart"] {
  .subpage__content {
    padding-left: 0;
    padding-right: 0;
  }
}

body[class*="checkout"] {
  .subpage__title {
    display: none;
  }
  .subpage__content {
    padding-left: 0;
    padding-right: 0;
  }
  .modal-backdrop {
    display: none !important;
  }
}

.w_content_area__content_heading {
  @include break(xs) {
    text-align: center;
  }
}

.store_title {
  text-align: center;
  font-size: 30px;
  color: var(--black);
  padding: 30px 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: var(--sans_light), Arial, sans-serif;
}

.store_content_wrapper {
  padding-bottom: 40px;
}

.store_hr {
  border-bottom: 1px solid var(--primary);
}

.store_button {
  color: var(--primary);
  background-color: #ffa302;
  border-radius: 25px;
  padding: 10px 20px;
  display: inline-block;
}

.general-header i {
  display: table-cell;
  font-size: 18px;
  line-height: 18px;
  vertical-align: middle;
  color: #ffffff !important;
}

.general-header span {
  display: table-cell;
  font-family: "open_sanssemibold";
  font-size: 18px;
  line-height: 18px;
  padding-left: 14px;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
}

.general-header-checkbox label {
  display: flex;
  margin-top: 20px;
}

/* Back Link Styles */
.b_back_link_container {
  margin-bottom: 20px;
  margin-top: 10px;

  @include break(xs) {
    text-align: center;
  }

  &--slash_delimited {

  }
}

.b_back_link_container__link {
  font-family: 'open_sansbold';
  font-size: 15px;
  line-height: 19px;
  color: #8d8d8d;
  display: inline-block;
  text-decoration: none;

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: var(--black);
  }

  .b_back_link_container--slash_delimited & {
    &:after {
      //content: ' /';
      //display: inline;
    }
    &:last-child:after {
      display: none;
    }
  }
}

.b_back_link___link_text {
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
}

/* Store Cart */
#store-cart-checkout-table tbody td {
  line-height: 1.2;
  width: 100%;
  font-size: 14px;
}

#store-cart-table-heading-qty,
#store-cart-table-heading-total,
#store-cart-table-heading-price {
  text-align: right;
  min-width: 70px;
}

.store-cart-table-cell-item,
.receipt_table tr,
.receipt_cell_item {
  padding: 12px 0;
  border-bottom: 1px solid #a6bcc9;
  font-family: "Open Sans", sans-serif;
}

#store-cart-footer-table {
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  tbody {
    width: 275px;
    margin-top: 15px;
  }
  td {
    padding: 10px;
  }
}

#receipt_heading_item {
  border-right: 0;
}

.receipt_cell_item,
.receipt_cell_price,
.receipt_cell_qty {
  border: 0 !important;
}

.store-cart-table-cell-total,
.store-cart-table-cell-item,
.store-cart-table-cell-qty,
.store-cart-table-cell-price {
  border-bottom: 1px solid #a6bcc9;
}

#store-cart-footer-subtotal-label,
#store-cart-footer-tax-label,
#store-cart-footer-total-label,
#store-cart-footer-shipping-label,
#store-cart-footer-paid-label,
#store-cart-footer-balance-label {
  text-align: left;
  padding: 6px 0 8px;
}

#store-cart-footer-total-label {
  font-weight: bold;
}

#store-cart-footer-subtotal-amount,
#store-cart-footer-tax-amount,
#store-cart-footer-shipping-amount,
#store-cart-footer-paid-amount,
#store-cart-footer-balance-amount {
  font-weight: normal;
  text-align: right;
}

#store-cart-footer-total-label,
#store-cart-footer-total-amount {
  border-top: 3px double black;
}

#store-cart-footer-balance-label,
#store-cart-footer-balance-amount {
  border-top: 1px solid black;
}

#store-cart-footer-total-amount {
  font-weight: bold;
}

#store-cart-footer-container {
  .b_cart__coupon_code_label {
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    display: block;
  }
  #store-cart-footer-coupon {
    display: flex;
    #coupon_code {
      height: 46px;
    }
    &-button {
      background-color: #0da1ea;
      color: white;
      padding: 15px 25px;
      border: 0;
      height: 46px;
      margin: 0 0 0 5px;
      font-family: "Open Sans Bold", sans-serif;
      font-weight: bold;
    }
  }
}

#store-cart-checkout-table {
  width: 100%;
}

.store-cart-table-cell-item {
  span {
    font-weight: bold;
  }
}

.store-cart-table-cell-price,
.store-cart-table-cell-qty,
.store-cart-table-cell-total {
  text-align: right;
  padding-left: 10px;
}

// SEARCH BAR
.store_search {
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  @include break(xs) {
    margin-top: 90px;
  }
  @media only screen and (max-width: 485px) {
    margin-top: 122px;
  }
}

#product_filter_keywords {
  width: 600px;
  padding: 14px 10px;
  border-radius: 10px;
  border: 2px solid #467905;
  @include break(sm) {
    width: 400px;
  }
  @include break(xs) {
    width: 300px;

  }
}

#product_filter_keywords:focus {
  outline: none;
}

.store_search_wrapper {
  position: relative;
}

.store_search_wrapper i {
  position: absolute;
  right: 14px;
  top: 10px;
  font-size: 30px;
}

.store-catalog-paging-sort-container {
  height: 60px;
}

.store-catalog-product-group-detail-thumbnails {
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
}

.store-catalog-product-group-detail-thumbnails p {
  text-align: center;
  width: 100%;
}

.store-catalog-product-group-detail-thumbnail a img {
  width: 100px;
  height: auto;
}
.b_product_detail__addtocart {
  @include break(xs) {
    margin-top: 12px;
  }
}
.b_product_detail__addtocart_recurring {
  margin-left: 11px;
  button {
    width: auto;
  }
  @include break(xs) {
    margin-top: 12px;
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .featured_products__wrapper {
    flex-direction: row!important;
  }
}
.general-header {
  background: #467905;
};
.general-header h2 {
  color: #ffffff;
};

#payment_option_layer_cc-saved {
  margin: 0 !important;
}

.by-function p {
  text-align: center;
  margin-top: 20px;
  color: #000;
}

#checkout-container .form_submit {
  margin-bottom: 40px;
}

.filterFormSubmit:hover {
  cursor: pointer;
}

// hidden because there are currently no products available to public, only for 'physician' and 'patient' user roles
#Xymogen {
  display: none;
}

.action-div-profile .profile-menu ul li.first {
  border-bottom: none!important;
  margin: 0!important;
}

.myaccount-list {
  height: 44px;
  vertical-align: middle;
  display: table-cell;

  .myaccount-link {
    color: white!important;
    margin-right: 25px;
    display: inline;

    @media (max-width: 992px) {
      display: block;
    }
  }

  .myaccount-link a, .myaccount-link a:hover {
    color: white!important;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.main_nav__link--portal {
  @media (max-width: 480px) {
    display: none;
  }
}

body.checkout .header-row-bot {
  display: none;
}

body.checkout .myaccount-list {
  display: none;
}

body.checkout .title-row .content-padding {
  display: none;
}

.homepage-banner {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.my-account .or-div-center {
  display: none;
}

.my-account .reg-col {
  display: none;
}
.main-line .main-container .content-row .account-box .account-body .dashboard-table .table-responsive .panel .table tbody tr td a.btn-danger {
  color: #fff;
}
