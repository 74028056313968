:root {
    font-size: 16px;
    --f_size: 1em;
    --slideHeight: 1000px;
    --container: 1600px;
}
* {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
}
p {
    margin-bottom: 20px;
}
p, li {
    font-size: 16px;
}
ul {
    list-style-type: none;
    padding:         0;
    margin:          0;
}
a {
    text-decoration: none;
    &:hover, &:active, &:focus {
        text-decoration: none;
    }
}
#admin_coverup_all {
    z-index: 0 !important;
}
