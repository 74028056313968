.video_gallery {
    display: flex;
    width:   100%;
}
.video_gallery__list {
    width: 50%;
}
.video_gallery__video {
    width: 50%;
}
