.b_product_detail {

}

.b_product_detail__heading {
    font-family: 'open_sansbold';
    font-size: 22px;
    line-height: 26px;
    color: #666;
    margin-bottom: 30px;

    &--light {
        font-family: 'open_sansregular';
    }
}

.b_product_detail__product {
    display: flex;
    flex-wrap: wrap;
}

.b_product_detail__product_item {
    margin: 0 0 40px 0;
    flex-basis: 100%;
    position: relative;
    transition: margin .2s ease;
    &:last-child {
        margin-bottom: 0;
    }

    @include break(sm) {
        width: 100%;
        flex-basis: 100%;
    }

    @include break(xs) {
        padding: 15px;
        flex-wrap: wrap;

        &:first-child {
            margin-top: 10px;
        }
    }
}

.b_product_detail__product_item_frame {
    max-width: 100%;
    margin: 0 auto;
}

.b_product_detail__top {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #009444;
}

.b_product_detail__bottom {
    //@include smu {
    //    position: absolute;
    //    bottom: 0;
    //}
}

.b_product_detail__image {
    text-align: center;
    padding-right: 15px;
}

.b_product_detail__image img {
    max-width: 100%;
}

.b_product_detail__product_name {
    margin-top: 15px;
    margin-bottom: 5px;
    color: var(--primary);
    &:after {
        display: none;
    }
    @media (max-width: 767px) {
        text-align: center;
    }
}

.b_product_add_to_cart {
    display: flex;
    align-items: center;
    margin: 15px auto;
    @media (max-width: 560px) {
        flex-direction: column;
    }
}

.b_product_detail__price {
    //margin-bottom: 10px;
    flex: 1;
    font-size: 20px;
    @media (max-width: 560px) {
        margin-bottom: 15px;
    }
    span {
        font-weight: 700;
    }
}
.b_product_detail__product_form {
    flex: 2;
    .store-catalog-product-qty-label {
        font-size: 18px;
    }
}

.b_product_detail__desc {
    margin-bottom: 0;
}
.b_product_detail__button {
    color: var(--white);
    background-color: var(--primary);
    border-radius: 25px;
    padding: 10px 20px;
    border: none;
    &:hover, &:active, &:focus {
        color: var(--white);
        background-color: var(--secondary);
    }
}

.b_product_detail__form_wrapper {
    display: flex;
    //margin-top: 30px;

    @include break(xs) {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }
}

.b_product_detail__low_quantity, .b_product_detail__out_of_stock {
    color: #ff643f;
    font-size: 15px;
}
