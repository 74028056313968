/**
 *  Responsive Breakpoints
 *  @access private
 *  @type Breakpoints
 */

$breakpoints : (
'xs': (max-width: 767px),
'sm' :(max-width: 991px),
'md' :(max-width: 1199px),
'lg' :(min-width: 1200px),
'xl-1600': (max-width: 1601px),
'xl-1300' :(max-width: 1301px),
'full-site': (max-width: 1920px)
);

/**
 *  Responsive Breakpoints
 *  @access private
 *  @type  Breakpoint function to define $breakpoints
 *  @param {Media Query} $breakpoints
 *
 */

@mixin break($breakpoint) {
    $raw-query : map-get($breakpoints, $breakpoint);

    @if $raw-query {
        $query : if(
                        type-of($raw-query) == 'string',
                        unquote($raw-query),
                        inspect($raw-query)
        );
        @media only screen and #{$query} {
            @content;
        }
    } @else {
        @error 'No value found for `#{$breakpoint}`. '
    }
}

