.main_nav__ul {
    &--header {
        display: flex;
        align-items: center;
    }
    &--footer {
    }
    ul {
        display: none;
    }
}
.main_nav__item {
    &--header {
        flex-grow:  1;
        display: flex;
        text-align: center;
        position: relative;
        &:last-child {
            .main_nav__link {
                color: var(--white);
                background-color: var(--primary);
                border-radius: 25px;
                &:hover, &:active, &:focus {
                    background-color: var(--secondary);
                }
            }
        }
    }
    &--footer {
        margin-bottom: 20px;
        @include break(xs) {
            text-align: center;
        }
    }
    &--active {
    }
}
.main_nav__link {
    &--header {
        position: relative;
        font-weight: 600;
        color: var(--black);
        font-size: 16px;
        flex-grow: 1;
        padding: 10px 0;
        &:hover, &:active, &:focus {
            color: var(--primary);
        }
    }
    &--footer {
        color: var(--black);
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1px;
        &:hover, &:active, &:focus {
            color: var(--primary);
        }

    }
    &--active {}
    &--has_children {
        & + ul {
            position: absolute;
            left: 0;
            top: 50px;
            .main_nav__link--header {
                border-radius: 0 !important;
            }
        }
    }
    &--button {
        color: var(--white) !important;
        background-color: var(--primary);
        border-radius: 25px;
        padding: 10px 20px;
        white-space: nowrap;
        &:hover, &:active, &:focus {
            color: var(--white);
            background-color: var(--secondary);
        }
        @include break(xs) {
            color: var(--black) !important;
            background-color: var(--white);
        }
    }
    &--button_480 {
        @extend .main_nav__link--button;
        display: none;
        @media (max-width: 480px) {
            display: block;
            margin-top: 10px;
        }
    }
}
.main_nav__link_span {
    &--header {
    }
    &--footer {
    }
    &--active {
    }
}
