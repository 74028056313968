.button {
    background-color: var(--primary);
    color:            var(--white);
    font-size:        12px;
    text-transform:   uppercase;
    padding:          10px 30px;
    border-radius:    50px;
    border:           0;
    letter-spacing: 1px;
    @extend %muli_regular;
    @include break(xs) {
        border-radius: 10px;
        padding: 15px 30px;
    }
    &--welcome {
        margin:     40px auto 0;
        text-align: center;
        display:    block;
        width:      145px;
    }
    &--main_slider {
        margin-top: 40px;
        text-align: center;
        display:    inline-block;
        min-width:  160px;
        @include break(xs) {
            margin: 40px auto 0;
        }
    }
    &--contact {
        margin:     20px auto 0;
        text-align: center;
        display:    block;
        width:      250px;
    }
    &--single_staff {
        margin:     40px auto 0;
        text-align: center;
        display:    block;
        width:      145px;
    }
    &:hover,
    &:active,
    &:focus {
        color:            var(--white);
        background-color: var(--secondary);
        outline: 0;
    }
}
