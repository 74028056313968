//:root {
//    --facebook: '\e800';
//    --facebook_clear: '\e807';
//    --instagram: '\e802';
//    --linkedin: '\e803';
//    --pinterest: '\e804';
//    --twitter: '\e805';
//    --youtube: '\e806';
//    --google_plus_clear: '\e808';
//    --instagram_clear: '\e809';
//    --linkedin_clear: '\e80a';
//    --pinterest_clear: '\e80b';
//    --twitter_clear: '\e80c';
//    --youtube_clear: '\e80d';
//    --yelp: '\e80e';
//    --yelp_clear: '\e80f';
//    --google_plus: '\e81e';
//}
//
//@mixin icon($iconName, $iconNameHover, $color, $colorHover) {
//    &:before {
//        font-family:  Fontello, sans-serif;
//        font-style:   normal;
//        font-size:    1.4em;
//        font-weight:  normal;
//        font-stretch: normal;
//        color: $color;
//        content: var($iconName);
//    }
//    &:hover::before,
//    &:active::before,
//    &:focus::before {
//        content: var($iconNameHover);
//        color: $colorHover;
//    }
//}

@mixin pngImg($imgName) {
    background-image: url("/assets/images/#{$imgName}.png");
}

@mixin jpgImg($imgName) {
    background-image: url("/assets/images/#{$imgName}.jpg");
}

@mixin footerLine {
    position: relative;
    &:before {
        @include break(xs) {
            display : none;
        }
        content: '';
        right: 0;
        top: 0;
        position: absolute;
        background-color: #beb7b3;
        height: 45px;
        width: 1px;
    }
}
