.pictures_image {
  max-width: 100%;
  max-height: 100px;
  display: block;
  margin: 5px auto;
}

.pictures_info {
  text-align: center;
  margin-top: 20px;
}

.pictures_wrapper {
  margin-top: 20px;
}
