:root {
    --sans_light: "Open Sans Light";
    --sans_regular: "Open Sans";
    --sans_bold: "Open Sans Bold";
    --muli_regular: "Muli regular";
}

@font-face {
    font-family: "Open Sans Bold";
}

%sans_regular {
    font-family: var(--sans_regular), Arial, sans-serif;
}
%sans_light {
    font-family: var(--sans_light), Arial, sans-serif;
}
%sans_bold {
    font-family: var(--sans_bold), Arial, sans-serif;
}
%muli_regular {
    font-family: var(--muli_regular), Arial, sans-serif;
}
.fa {
    font-family: "FontAwesome" !important;
}
