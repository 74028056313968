.main_slider__wrapper {
    position        : relative;
    height          : var(--slideHeight);
    display         : flex;
    justify-content : flex-start;
    align-items     : center;
    @include break(xs) {
        height: 500px;
    }
}
.main_slider__tint {
    @include break(xs) {
        background-color: rgba(#11181b, 0.65);
        position : absolute;
        bottom   : 0;
        right    : 0;
        left     : 0;
        top      : 0;
    }
}
.main_slider__content {
    @include break(xs) {
        position : absolute;
        bottom   : 40px;
        left     : 0;
        right    : 0;
    }
}
.main_slider__title {
    @extend %sans_light;
    font-size      : 3.75rem;
    text-transform : uppercase;
    letter-spacing : 6px;
    @include break(xs) {
        font-size  : 2rem;
        text-align : center;
        color      : var(--white);
    }
}
.main_slider__text {
    font-size : 1.25rem;
    @extend %sans_light;
    @include break(xs) {
        text-align : center;
        color      : var(--white);
        font-size: 1rem;
    }
}
.main_slider__button_wrapper {
    @include break(xs) {
        text-align: center;
    }
}

.swiper-slide-active .main_slider__content * {
    position: relative;
}

@keyframes slider {
    0% {
        opacity: 0;
        right:    500px;
    }
    100% {
        opacity: 1;
        right:    0;
    }
}


.swiper-slide-active .main_slider__content * {
    /*animation: slider (0.9s + 0.25 * $i) ease;*/
    animation: slider 0.8s ease;
}


