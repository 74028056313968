.social {}
.social__wrapper {
    @include break(md) {
        .header & {
            margin-right: 50px;
        }
    }
    @include break(xs) {
        .header & {
            margin-right: auto;
        }
    }
}

.soci_link {
    margin-right: 10px;
    &:hover, &:active, &:focus {
        .fa-circle {
            color: var(--primary);
        }
        .fa-social-icon {
            color: var(--white);
        }
    }
}
.fa-stack {
    width: 28px !important;
    height: 28px !important;
}
.fa-circle {
    color: #000000;
    font-size: 0.9em !important;
}
.fa-social-icon {
    color: #ffffff;
    font-size: 14px !important;
    top: -15px;
}

@include break(xs) {
    header {
        .fa-circle {
            color: var(--white);
        }
        .fa-social-icon {
            color: var(--primary);
        }
        .soci_link {
            &:hover, &:active, &:focus {
                .fa-circle {
                    color: var(--secondary);
                }
                .fa-social-icon {
                    color: var(--white);
                }
            }
        }
    }
}

.social__link {
    margin: 0 4px;
}
