.photo_gallery {
    width: 100%;
}
.photo_gallery__title {
    text-align:     center;
    @extend %sans_light;
    font-size:      30px;
    color:          var(--black);
    padding:        30px 0;
    text-transform: uppercase;
    letter-spacing: 2px;
}
