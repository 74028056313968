.prof_logo_slider {
    padding: 45px 0;
    background-color: #e2e2e2;
}
.prof_logo_slider__content {
    padding: 20px;
    background-color: #ffffff;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
}
