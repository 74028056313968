.single_staff {
    display: flex;
    flex-wrap: wrap;
    width:   100%;
}
.single_staff__img {
    width: 50%;
    @include break(md) {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
.single_staff__bio {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include break(md) {
        width: 100%;
    }
}
.single_staff__bio_wrapper {
    padding: 7% 20%;
    @include break(xs) {
        padding: 7%;
    }
}
.single_staff__name {
    text-align:     center;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom:  30px;
    font-size:      34px;
    @extend %sans_bold;
    span {
        @extend %sans_bold;
    }
}
.single_staff__desc {
    text-align: center;
    color: #8b8888;
    font-size: 17px;
}

.about-us .subpage__title {
    display: none;
}

.about-us .b_staff_subpage__title {
    display: none;
}
