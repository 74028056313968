.header__pp_wrapper--hide-xs {
  @media only screen and (max-width: 480px) {
    display: none;
  }
}
.b_header_cart--mobile {
    @include break(md) {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 0;
    }
}
.b_header_cart__cell {
    @include break(md) {
        margin: 0 0 30px 0;
        padding: 0 9px;
    }
}
.b_header_cart {
    @include break(md) {
        flex-wrap: wrap;
    }
    @include break(xs) {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: white;
        padding: 20px 0 10px;
        margin: 0;
        border-bottom: 1px solid #ccc;
        //height: $cart_mobile_height;
        z-index: 10;
    }
}
.fixed-cart #cartWrapper{
    overflow-y: scroll;
    max-height: 500px;
    @media(max-width: 767px) {
        height: auto;
    }
}
#header-shopping-cart #store_small_cart_container {
    background-color: #f6f6f6 !important;
}
#store_small_cart_container {
    @media(min-width: 768px) {
        top: 50%;
        transform: translateY(-50%);
    }
    @media(max-width: 767px) {
        position: fixed;
        bottom: 0;
        top: auto; left: 0;
        height: 100%;
        width: 100%;
        margin-left: 0;
        padding-bottom: 50px;
        padding-left: 0;
        padding-right: 0;
    }
}
#small_cart_bottom {
    @media(max-width: 767px) {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
    }
}
.small_cart_heading_total,
.small_cart_heading_qty,
.small_cart_heading_price,
.small_cart_heading_item,
#header-shopping-cart #store_small_cart_container .small_cart_table tbody .small_cart_line_row td.small_cart_cell_qty input,
.small_cart_cell_total,
.small_cart_cell_qty,
.small_cart_cell_price,
.small_cart_cell_item_prod_sname {
    color: black !important;
}
#small_cart_footer_value_subtotal,
#store_small_cart_container .small_cart_table tbody tr #small_cart_footer #small_cart_footer_table #small_cart_footer_label_subtotal {
    color: #0085be;
}
#small_cart_footer_label_subtotal {
    padding-left: 45px;
    text-align: left;
    font-family: 'open_sansbold';
    font-size: 24px;
    color: #0085be;
    text-transform: uppercase;
    border: none;
}
.mini_cart_buttons_wrapper {
    @media(max-width: 500px) {
        flex-wrap: wrap;
        div {
            width: 50%;
            margin: 0 !important;
            text-align: center;
            &:nth-of-type(3) {
                margin-top: 10px !important;
            }
            &:nth-of-type(4) {
                margin-top: 10px !important;
            }
        }
    }
}
.mini_cart_buttons_wrapper__item {
    margin: 0 5px !important;
}
.small_cart_table tr.small_cart_line_row td,
#small_cart_footer,
.small_cart_table tr.alt td {
    background-color: #fff;
}
.small_cart_cell_item_prod_sname,
.small_cart_cell_item_prod_group_sname {
    padding-left: 10px;
}
#store_small_cart_container {
    padding: 25px 15px;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.3);
    border: medium none;
    background-color: #fff;
    position: fixed;
    @media(max-width: 500px) {
        #store_small_cart_main {
            padding-bottom: 90px;
        }
    }
}
#store_small_cart_container .small_cart_table thead {
    background: none;
    tr th {
        background-color: #fff;
        border-right: none;
    }
}
.closeBtn {
    margin-left: 5px;
    @media(max-width: 500px) {
        margin-left: 0;
    }
}
#store_small_cart_container .small_cart_table tbody .small_cart_line_row td.small_cart_cell_qty input {
    height: 29px !important;
    width: 33px !important;
    background: none;
    font-family: 'open_sansregular';
    font-size: 16px !important;
    color: #000;
    border: 1px solid #5a564d;
}
.small_cart_cell_item_prod_sname,
.small_cart_cell_item_prod_group_sname a,
#store_small_cart_container .small_cart_table tbody .small_cart_line_row td.small_cart_cell_total,
#store_small_cart_container .small_cart_table tbody .small_cart_line_row td.small_cart_cell_price {
    font-family: 'open_sansregular';
    font-size: 16px;
}
.small_cart_table tr.small_cart_line_row td {
    @media(max-width: 500px) {
        padding: 0;
    }
}
.small_cart_cell_item_pic img {
    border: none;
}

.ui-dialog .ui-dialog-titlebar-close:before {
    display : none !important;
}

#small_cart_bottom {
    .b_button {
        width: auto;
        display: block;
        padding: 10px 25px;
        font-family: sans-serif;
        font-size: 100%;
        line-height: 1.15;
    }
}
