.wrapper {
    background-color: var(--white);
}
.wrapper__page {
    width:            1920px;
    margin:           0 auto;
    background-color: #fafafa;
    display:          block;
    position:         relative;
    z-index:          1;
    @include break(full-site) {
        width: 100%;
    }
}
.wrapper__welcome {
    display: flex;
    flex-wrap: wrap;
}
.wrapper__page {
    background-color: var(--cream);
}
.wrapper__subpage {
    //padding-top: 183px;
    padding-bottom: 40px;
}
