.staff_slider {
    width: 100%;
}
.staff_slider__title {
    text-align:     center;
    @extend %sans_light;
    font-size:      30px;
    color:          var(--black);
    padding:        30px 0;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.staff_slider__content {

    position: absolute;
    bottom:   0;
    left:     0;
    right:    0;
}
.staff__name {
    font-size:        30px;
    text-align:       center;
    @extend %sans_light;
    text-transform:   uppercase;
    letter-spacing:   2px;
    margin:           0;
    background-color: rgba(#365f04, 0.8);
    color:            var(--white);
    display:          block;
    padding:          30px 0;
    transform-style:  preserve-3d;
    transform-origin: 50% 0;
    transition:       transform .3s;
    position:         relative;
    &:before {
        content:          attr(data-hover);
        color:            var(--white);
        background-color: var(--secondary);
        height:           100%;
        width:            100%;
        top:              100%;
        left:             0;
        position:         absolute;
        transform:        rotateX(-90deg);
        transition:       background .3s;
        transform-origin: 50% 0;
        pointer-events:   none;
        padding:          30px 0;

    }
}
.staff_slider__img {
    display: flex;
    justify-content: center;
    overflow: hidden;
}
.staff_slider__link {
    &:hover, &:active, &:focus {
        .staff__name {
            transform: rotateX(90deg) translateY(-22px);
        }
    }
}
