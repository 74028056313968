.testimonial_slider {
    width:   100%;
    padding: 40px 0;
    background-color: #e2e2e2;
}
.testimonial_slider__container {
    width: 970px;
    display: block;
    margin: 0 auto;
    @include break(md) {
        width: 100%;
    }
}
.testimonial_slider__title {
    @extend %sans_light;
    text-transform: uppercase;
    text-align: center;
    color: var(--black);
    line-height: 1.4;
    font-size: 30px;
    letter-spacing: 2px;
}
.testimonial_slider__content {
    @extend %sans_light;
    font-style: italic;
    font-size: 20px;
    text-align: center;
}
.testimonial_slider__signature {
    text-align: center;
    @extend %sans_regular;
    font-size: 20px;
    margin: 6px 0;
}
