.b_product_qty {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .b_groupsell__cart_cell & {
        @include break(sm) {
            flex-grow: 0;
        }
    }

    @include break(xs) {
        justify-content: center;
        margin-bottom: 0;
        min-width: 1px;
    }
    @media (max-width: 360px) {
        margin-bottom: 10px;
    }
    .store-catalog-product-qty-updown-up > a {
        position: relative;
        top: 0;
    }
}

.b_product_qty__item {
    margin-right: 18px;
}

.b_product_qty__input {
    height: 38px;
    width: 54px;
    text-align: center;
    border: 1px solid #b5b5b5;
}

.b_product_qty__label {
    font-family: 'open_sansregular';
    font-size: 17px;
    line-height: 30px;
    margin: 0;
}

.b_product_qty__up_btn, .b_product_qty__down_btn {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    transition: border-color .2s ease;
}

.b_product_qty__up_btn {
    border-width: 0 8px 14px 8px;
    border-color: transparent transparent #e6e6e8 transparent;
    margin-bottom: 5px;

    &:hover, &:active {
        border-color: transparent transparent blue transparent;
    }
}

.b_product_qty__down_btn {
    border-width: 14px 8px 0 8px;
    border-color: #e6e6e8 transparent transparent transparent;

    &:hover, &:active {
        border-color: blue transparent transparent transparent;
    }
}
