.service {
    width:     100%;
    display:   flex;
    flex-wrap: wrap;
}
.service__item {
    width:    calc(100% / 3);
    position: relative;
    overflow: hidden;
    @include break(xl-1300) {
        width: calc(100%/2);
    }
    @include break(xs) {
        width: 100%;
    }
}
@for $i from 1 to 7 {
    .service__img_#{$i} {
        @include pngImg(services/service-#{$i});
        background-position-x: center;
        width:           100%;
        height:          550px;
        background-size: cover;
        background-repeat: no-repeat;
        @include break(md) {
            height: 450px;
        }
    }
}
.service__link {
    display: block;
    &:hover, &:active, &:focus {
        .service__text span {
            transform: rotateX(90deg) translateY(-22px);
        }
    }
}
.service__text {
    position:         absolute;
    left:             0;
    bottom:           0;
    right:            0;
    span {
        position:         relative;
        text-align:       center;
        text-transform:   uppercase;
        display:          block;
        padding:          45px 0;
        transform-style:  preserve-3d;
        transform-origin: 50% 0;
        transition:       transform 0.3s;
        letter-spacing:   2px;
        font-size:        24px;
        color:            var(--white);
        background-color: rgba(#365f04, 0.8);
        @extend %sans_light;
        @include break(md) {
            padding: 30px 0;
            font-size: 17px;
        }
        &:before {
            content:          attr(data-hover);
            position:         absolute;
            top:              100%;
            left:             0;
            width:            100%;
            height:           100%;
            padding:          45px 0;
            background-color: var(--secondary);
            color:            var(--white);
            transform:        rotateX(-90deg);
            transition:       background 0.3s;
            transform-origin: 50% 0;
            pointer-events:   none;
            @include break(md) {
                padding: 30px 0;
            }
        }
    }
}
