.swiper-container {
    &--map {
        background-color: #f0e7e2;
    }
}
.swiper-wrapper {
}
.swiper-slide {
    &--main_slider {
        height:          var(--slideHeight);
        background-size: cover;
        background-position-x: center;
        @include break(xs) {
            height: 500px;
        }
    }
    &--testimonial {
        padding: 30px;
    }
    &--map {
        @include break(md) {
            flex-wrap: wrap;
            padding: 50px 250px;
        }
        @include break(sm) {
            padding: 50px;
        }
        display: flex;
        padding: 30px;
    }
}
.swiper-pagination {
    .swiper-pagination-bullet {
        width:            6px;
        height:           6px;
        opacity:          1;
        margin:           0 8px !important;
        background-color: var(--white);
        &-active {
            background-color: var(--primary);
        }
    }
    &--staff {}
    &--map {}
    &--video {}
    &--featured {
        top: 55px;
        height: 25px;
        text-align: right;
        .swiper-pagination-bullet {
            background-color: #171717;
            &-active {
                background-color: var(--primary);
            }
        }
        @include break(xs) {
            position: relative;
            text-align: center;
            top: -15px;
        }
    }
}

#serviceSlider {
    .swiper-button-prev {
        background-image: url('../../assets/images/slider/slider_arrow.png');
        height: 50px;
        width: 50px;
        border-width: 2px;
        border-style: solid;
        border-color: $primary;
        transform: rotate(180deg);
        background-position: 50% 50%;
        transition: background-position .4s;
        &:hover {
            background-position: 85% 50%;
        }
    }
    .swiper-button-next {
        background-image: url('../../assets/images/slider/slider_arrow.png');
        height: 50px;
        width: 50px;
        border-width: 2px;
        border-style: solid;
        border-color: $primary;
        background-position: 50% 50%;
        transition: background-position .4s;
        &:hover {
            background-position: 85% 50%;
        }
    }
}
