.b_product_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    &--items_centered {
        justify-content: center;
    }

    &--items_spacebetween {
        justify-content: space-between;
    }

    @include break(sm) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.b_product_box__item {
    border-bottom: 1px solid var(--primary);
    padding: 40px 0;
    width: 45%;
    @include break(sm) {
        display: flex;
        flex-direction: column;
    }
    @include break(xs) {
        width: 100%;
    }
}

.b_product_box__item_image_wrapper {
    padding: 45px 48px 0 45px;
}

.b_product_box__item_image_link {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 21px;
    height: 237px;
    position: relative;

    &--no_padding {
        padding: 0;
        height: auto;
    }
}

.b_product_box__item_image {
    max-width: 100%;
    max-height: 100%;

    &--fellowship {
        width: 253px;
        height: 158px;
        max-width: none;
    }
}

.b_product_box__item_text_wrapper {
    padding: 13px 10px;
    &--even_margin {
        padding: 0;
    }
}

.b_product_box__title {
    text-align: center;
    font-size: 30px;
    color: var(--black);
    padding-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: var(--sans_light),Arial,sans-serif;
    @include break(sm) {
        flex-grow: 1;
    }
}

.b_product_box__item_text_link {
    &:hover, &:active, &:focus {
        color: var(--primary);
    }
}

.b_product_detail__photo_lpic_wrapper {
    text-align: center;

    img {
        max-width: 100%;
        max-height: 200px;
    }
}
.b_button {
    color: var(--white);
    background-color: var(--primary);
    border-radius: 25px;
    padding: 10px 20px;
    border: none;
    margin: auto;
    text-align: center;
    display: block;
    width: 140px;
    margin-top: 14px;
    &:hover, &:active, &:focus {
        color: var(--white);
        background-color: var(--secondary);
    }
}
