:root {
    --white: #ffffff;
    --black: #000000;

    --primary: #467905;
    --secondary: #ff643f;
}
$white: #ffffff;
$black: #000000;

$primary: #467905;
$secondary: #ff643f;

