.header {
    padding          : 18px 0;
    position         : absolute;
    left             : 0;
    right            : 0;
    top              : 0;
    z-index          : 999;
    display          : flex;
    width            : var(--container);
    background-color : transparent;
    margin           : 0 auto;
    @include break(xl-1600) {
        width   : 100%;
        padding : 18px 20px;
    }
    @include break(xs) {
        flex-wrap: wrap;
    }
}
@for $i from 1 through 3 {
    .header__col_#{$i} {
        flex-grow : $i;
        @if ($i == 3) {
            @include break(xs){
                width: 35%;
                max-width: 35%;
            }
            padding-top : 40px;
            width       : 40%;
            max-width: 40%;
        }
        @else {
            @include break(xs) {
                width: 30%;
                max-width: 35%;
            }
            width : 20%;
            max-width: 20%;
        }
    }
}
.header__row {
    @include break(xs) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    display: none;
}

.header__info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include break(xs) {
        flex-direction: column;
    }
}
.header__social {
    .social__wrapper {
        @include break(xs) {
            margin-right: auto;
        }
    }
}
.header__pp_wrapper {
    @include break(xs) {
        display: flex;
        margin-top: 10px;
    }
}
.header__address {
    @include break(xs) {
        color : var(--white);
        margin: 0;
        display: none;
    }
    @include break(md) {
        font-size: 12px;
    }
    display      : inline-block;
    font-weight  : 600;
    font-size    : 15px;
    margin-right : 30px;
    &:before {
        font-family  : "Font Awesome 5 Free", sans-serif;
        font-weight: 400;
        content      : '\f1d8';
        color        : var(--primary);
        font-size    : 14px;
        margin-right : 10px;
        @include break(xs) {
            color : var(--white);
        }
    }

}
.header__phone {
    @include break(xs) {
        color : var(--white);
    }
    @include break(md) {
        font-size: 12px;
    }
    display     : inline-block;
    font-weight : 600;
    color       : var(--black);
    font-size   : 16px;
    &:before {
        font-family  : "Font Awesome 5 Free", sans-serif;
        font-weight: 900;
        content      : '\f095';
        color        : var(--primary);
        font-size    : 15px;
        margin-right : 10px;
        @include break(xs) {
            color : var(--white);
        }
    }
    &:hover, &:active, &:focus {
        color : var(--primary);
    }

}
.header__logo_wrapper_color {
    display: block;
    @include break(xs) {
        display: none;
    }
}
.header__logo_wrapper_white {
    display: none;
    @include break(xs) {
        display: block;
    }
}
.header__mmenu {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    span {
        width: 30px;
        height: 4px;
        margin-bottom: 4px;
        background-color: var(--primary);
        display: block;
        @include break(xs) {
            background-color: var(--white);
        }
    }

}
@include break(md) {
    .header__col_3 {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: flex-start;
        &:first-child {
            justify-content: flex-start;
        }
    }
}

/* Store Header Cart */
.b_header_cart {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
}
.b_header_cart__cell {
    margin-left: 30px;
    a {
        color: var(--black);
        &:hover, &:active, &:focus {
            color: var(--black);
        }
    }
}
.b_header_cart_icon {
    color: var(--primary);
}
