.welcome_section {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include break(md) {
        width: 100%;
        order: 1;
    }
    &--image {
        align-items: stretch;
        @include break(md) {
            order: 2;
        }
        @include break(xs) {
            align-items: center;
        }
        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }
}
.welcome_section__wrapper {
    width: 100%;
    padding: 7% 10%;
    @include break(xs) {
        padding: 6%;
    }
}
.welcome_section__title {
    text-align: center;
    color: var(--black);
    @extend %sans_light;
    font-size: 34px;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.welcome_section__text {
    p {
        color: #8b8888;
        @extend %sans_regular;
        font-size: 18px;
        text-align: center;
        line-height: 1.6;
    }
}
