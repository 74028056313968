@mixin footer-side-padding {
    @include break(sm) {
        padding : 0 40px;
    }
    padding : 0 30px;;
}

.footer {
    background-color : #f0e7e2;
}
.footer__wrapper {
    padding : 60px 0;
    width   : 1600px;
    margin  : 0 auto;
    display : flex;
    @include break(xl-1600) {
        width   : 100%;
        padding : 60px 20px;
    }
    @include break(md) {
    flex-wrap : wrap;
}
}
.footer__row {
    width : 100%;
}
.footer__col {
    @include break(xs) {
        width     : 100%;
        flex-wrap : wrap;
    }
    width   : 50%;
    flex-grow : 1;
    display   : flex;
}
.footer__col_title {
    @include break(xs) {
        text-align : center;
    } margin-top   : 0;
    text-transform : uppercase;
    font-size      : 14px;
    font-weight    : normal;
    margin-bottom  : 30px;
}
.footer__logo {
    @include break(md) {
        padding-right   : 0;
        display         : flex;
        justify-content : center;
        &:before {
            display : none;
        }
    } flex-grow   : 1;
    padding-right : 60px;
    @include footerLine();
}
.footer__disclaimer {
    @include break(xs) {
        text-align : center;
    }
    flex-grow : 1;
    @include footer-side-padding();
    @include footerLine();

    p:nth-child(4) {
        br {display: none;}
        strong {font-weight: normal;}
    }
}
.footer__content {
    flex-grow : 1;
    @include footer-side-padding();
    @include footerLine();
}
.footer__nav {
    flex-grow : 1;
    @include footer-side-padding();
    @include footerLine();
    @include break(md) {
        &:before {
            display : none;
        }
    }
}
.footer__social {
    @include break(md) {
        margin          : 30px 0;
        padding-left    : 0;
        display         : flex;
        justify-content : center;
    } flex-grow  : 1;
    padding-left : 30px;
}
.footer__address {
    @include break(xs) {
        text-align : center;
    } font-size   : 14px;
    position      : relative;
    margin-bottom : 20px;
    &:before {
        @include break(xs) {
            display : none;
        } font-family : "Font Awesome 5 Free", sans-serif;
        font-weight: 400;
        content       : "\f1d8";
        color         : #467905;
        position      : absolute;
        font-size     : 14px;
        top           : 0;
        left          : -30px;
    }
}
.footer__phone {
    @include break(xs) {
        text-align    : center;
        display       : block;
        margin-bottom : 20px;
    } color   : var(--black);
    position  : relative;
    font-size : 14px;
    &:before {
        @include break(xs) {
            display : none;
        } font-family : "Font Awesome 5 Free", sans-serif;
        font-weight: 900;
        content       : "\f095";
        color         : #467905;
        position      : absolute;
        font-size     : 14px;
        top           : 0;
        left          : -30px;
    }
}
.footer__wtm {
    @include break(xs) {
        flex-direction : column;
    } background-color : #4f5051;
    padding            : 50px;
    width              : 100%;
    display            : flex;
    justify-content    : center;
    align-items        : center;
    p {
        margin : 0 30px;
        color  : var(--white);
        @include break(xs) {
            margin: 20px 0 0;
            text-align : center;
        }
        a {
            color : var(--white);
            &:hover, &:active, &:focus {
                color : var(--secondary);
            }
        }
    }
}
