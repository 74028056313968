.subpage {}
.subpage__page {

}
.subpage__content {
    background-color: var(--white);
    padding: 50px 40px;
    @include break(xs) {
        padding: 20px;
    }
}

.subpage__content--main {
    h2,
    h3,
    h4,
    h5,
    h6 {
        //@extend %roboto_bold;
        color : var(--primary);
    }
    p {
        font-size: 1rem;
        a {
            color : var(--primary);
        }
    }
    ul {
        margin-bottom: 20px;
        //@extend %roboto_regular;
        li {
            line-height: 1.4;
            position: relative;
            font-size: 1rem;
            margin-left: 0;
            padding: 0 0 10px 30px;
            background: url("/assets/images/li_bg.png") no-repeat scroll left 1px transparent;
        }
        &.column-two {
            column-count: 2;
            @include break(xs) {
                column-count: 1;
            }
        }
        ul {
            margin-top: 16px;
            margin-left: 10px;
            li {
                background: url("/assets/images/inner_li_bg.png") no-repeat scroll left 1px transparent;
            }
        }
    }
    table {
        margin: 10px 0 20px;
        td {
            //@extend %roboto_regular;
            font-size: 1rem;
        }
    }
}

.contact-us {
    .contact_form {
        width: 100%;
        border-top: 1px solid var(--primary);
        border-bottom: 1px solid var(--primary);
    }
    .contact_form__wrapper {
        padding-top: 4%;
        padding-bottom: 4%;
    }
    .contact_form__title {
        display: block;
    }
}

.store {
    .wrapper__subpage {
        padding: 0;
    }
}

.store,
.subpage,
.contact-us {
    .header {
        position: relative;
        border-bottom: 1px solid var(--primary);
    }
    @include break(xs) {
        header .fa-circle {
            color: var(--black);
        }
        header .fa-social-icon {
            color: var(--white);
        }
        .header__mmenu span {
            background-color: var(--primary);
        }
        .header__phone {
            color: var(--primary);
            &:before {
                color: var(--primary);
            }
        }
        .main_nav__link--button {
            color: var(--white) !important;
            background-color: var(--primary);
            &:hover, &:active, &:focus {
                background-color: var(--secondary);
            }
        }
        .header__logo_wrapper_color {
            display: block;
        }
        .header__logo_wrapper_white {
            display: none;
        }
    }
}
.subpage__multi_variant {
    text-align: center;
    color: orange;
    font-size: 20px;
    display: none;
}

/* Content */
@include break(sm) {
    .staff_subpage__headshot {
        margin: auto;
    }
    .staff_subpage__name {
        text-align: center;
    }
}

.form_error_main {
    ul li {
        background: none!important;
    }
}
